import {
  GET_ATTENDANCES,
  GET_ATTENDANCES_REQUEST,
  GET_ATTENDANCES_FAIL,
  GET_TODAY_ATTENDANCES,
  GET_TODAY_ATTENDANCES_REQUEST,
  GET_TODAY_ATTENDANCES_FAIL,

  GET_QUERY_ATTENDANCES,
  GET_QUERY_ATTENDANCES_REQUEST,
  GET_QUERY_ATTENDANCES_FAIL,

  GET_ATTENDANCE,
  GET_ATTENDANCE_REQUEST,
  GET_ATTENDANCE_FAIL,
  ATTENDANCE_RESET,
} from "../types";

const initState = {
  error: null,
  loading: false,
  attendances: [],
  todayattendances: [],
  queryattendances: [],
  attendance: {},
};

const peoplesReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ATTENDANCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ATTENDANCES:
      return {
        ...state,
        loading: false,
        attendances: action.payload.data,
      };
    case GET_ATTENDANCES_FAIL:
      return {
        ...state,
        loading: false,

        error: action.payload,
      };

    case GET_TODAY_ATTENDANCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TODAY_ATTENDANCES:
      return {
        ...state,
        loading: false,
        todayattendances: action.payload.data,
      };
    case GET_TODAY_ATTENDANCES_FAIL:
      return {
        ...state,
        loading: false,

        error: action.payload,
      };


    case GET_QUERY_ATTENDANCES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QUERY_ATTENDANCES:
      return {
        ...state,
        loading: false,
        queryattendances: action.payload.data,
      };
    case GET_QUERY_ATTENDANCES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case GET_ATTENDANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ATTENDANCE:
      return {
        ...state,
        loading: false,
        attendance: action.payload.data,
      };
    case GET_ATTENDANCE_FAIL:
      return {
        ...state,
        loading: false,

        error: action.payload,
      };
    case ATTENDANCE_RESET:
      return {
        error: null,

        loading: false,
        attendances: [],
        attendance: {},
      };

    default:
      return state;
  }
};

export default peoplesReducer;
