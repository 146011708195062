import axios from "axios";
import {
  GET_ATTENDANCES,
  GET_ATTENDANCES_REQUEST,
  GET_ATTENDANCES_FAIL,
  GET_TODAY_ATTENDANCES,
  GET_TODAY_ATTENDANCES_REQUEST,
  GET_TODAY_ATTENDANCES_FAIL,
  
  GET_QUERY_ATTENDANCES,
  GET_QUERY_ATTENDANCES_REQUEST,
  GET_QUERY_ATTENDANCES_FAIL,

  GET_ATTENDANCE,
  GET_ATTENDANCE_REQUEST,
  GET_ATTENDANCE_FAIL,
} from "../types";

const token = localStorage.getItem("token:key");

export const getQueryAttendance = (query) => async (dispatch) => {
  try {
    const URL = `/api/v1/attendance/query`;
    dispatch({
      type: GET_QUERY_ATTENDANCES_REQUEST,
    });
    const data  = await axios.post(URL, query, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_QUERY_ATTENDANCES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_QUERY_ATTENDANCES_FAIL,
      error:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getTodaysAttendances = () => async (dispatch) => {
  try {
    const URL = `/api/v1/attendance/today`;
    dispatch({
      type: GET_TODAY_ATTENDANCES_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_TODAY_ATTENDANCES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_TODAY_ATTENDANCES_FAIL,
      error:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getAttendances = () => async (dispatch) => {
  try {
    const URL = `/api/v1/attendance`;
    dispatch({
      type: GET_ATTENDANCES_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ATTENDANCES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ATTENDANCES_FAIL,
      error:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getAttendance = (id) => async (dispatch) => {
  try {
    const URL = `/api/v1/attendance/${id}`;
    dispatch({
      type: GET_ATTENDANCE_REQUEST,
    });
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_ATTENDANCE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ATTENDANCE_FAIL,
      error:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};
